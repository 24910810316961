import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_ROOT_API;
export default class EventService {

    getEvents() {
        return axios.get(API_URL + '/mantenimiento/mantenimiento/', { headers: authHeader() }) .then(response => {

            let events = response.data;
            for (let i = 0; i < events.length; i++) {

                console.log(events[i].date)

                //remove timezone
                events[i].start = events[i].date.substring(0, 10);

                events[i].title = events[i].title 
                //events[i].start = events[i].date; 

                events[i].color =  events[i].color ;
            }
            return response.data;
        }).catch(error => {
            return error.response.data;
    })
    }

    addEvent(event) {
        return axios.post(
            API_URL + '/mantenimiento/mantenimiento/',
            event,
            { headers: authHeader() })
            .then(response => {

                if(response.status == 200){
                    return response.data;
                } 

            }).catch(error => {
                
                return error.response;

        })
    }  


    
}